import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import { MainLayout, PostCardFull, PaginationHelper } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Layout, Row, Col, Typography } from 'antd'
const { Title, Paragraph, Text } = Typography
import Prism from 'prismjs'
import { Parallax } from 'rc-scroll-anim'
import Wave from "../images/icons/wave.svg";
const { Content } = Layout
/**
* Latest posts page (/latest)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Latest = ({ data, location, pageContext }) => {
    const posts = data.posts.edges

    const page = {
        page: {
            title: `The Latest`,
            meta_title: ``,
            meta_description: `These are the latest posts by Joe Czubiak.`,
            name: ``,
            feature_image: ``,
            description: `These are the latest posts by Joe Czubiak.`,
        },
    }

    useEffect(() => {
        Prism.highlightAll()
    })

    const Hero = () => {
        return (
            <Content>
                <Row className={`hero latest`}>
                    <Col xs={24} sm={24}>
                        <Title level={1}>The Latest</Title>
                    </Col>
                    <Col xs={24}>
                        <p>The latest posts listed chronologically</p>
                        <Link to="/blog" style={{ color: `#fff`, marginTop: `1.4em`, display: `inline-block`, border: `solid 1px #fff`, 'fontSize': `14px`, padding: `5px` }}>View posts as grid</Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} style={{ overflow: `hidden` }}>
                        <Parallax
                            animation={{ x: `-10%`, playScale: [0, 1.3] }}
                            style={{ transform: `translateX(-40%)`, zIndex: 1, opacity: 1 }}
                            className=""
                        >
                            <Wave className={`hero-wave`} />
                        </Parallax>
                    </Col>
                </Row>

            </Content>
        )
    }

    return (
        <>
            <MetaData location={location} data={page} />
            <MainLayout isHome={false} hero={<Hero />} className="latest">
                <Row
                    gutter={[16, 24]}
                    type="flex"
                    justify={`space-around`}
                >
                    {posts.map(({ node }, i) => (
                        <Col
                            key={i}
                            xs={24}
                            sm={24}
                            md={18}
                            lg={18}
                        >
                            <PostCardFull post={node} />
                        </Col>
                    ))}
                </Row>
                <PaginationHelper pageContext={pageContext} />
            </MainLayout>
        </>
    )
}

Latest.propTypes = {
    data: PropTypes.shape({
        posts: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Latest

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query LatestPostQuery($limit: Int!, $skip: Int!) {
    posts: allMarkdownRemark(
        filter: {frontmatter: {collectionType: {eq: "post"}, public: {eq: true} }}
        limit: $limit
        skip: $skip
        sort: { fields: [frontmatter___published_at], order: DESC }
      ) {
          edges {
              node {
                  ...LatestPostFields
              }
          }
      }
  }
`
